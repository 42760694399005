import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WelcomeUserComponent } from './dashboard/welcome-user/welcome-user.component';
import { FileExportsComponent } from './dashboard/file-exports/file-exports.component';
import { FileExportsDialogComponent } from './dashboard/file-exports/file-exports-dialog/file-exports-dialog.component';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';

@NgModule( {
    declarations: [
        FileExportsComponent,
        DashboardComponent,
        WelcomeUserComponent,
        FileExportsDialogComponent,
],
    imports:      [
        DashboardRoutingModule,
        SharedModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
    ],
} )
export class DashboardModule {
}
