<ng-template #dialogHeadline>
    <div class="standard-dialog--headline">
        Imprint
    </div>
</ng-template>

<ng-template #dialogContent>
    <div class="standard-dialog--content imprint-dialog">
        <p>
            <strong>adidas AG</strong>
        </p>
        <p>
            Adi-Dassler-Strasse 1<br>
            91074 Herzogenaurach<br>
            Germany
        </p>
        <p>
            p+49 (0) 9132 84-0<br>
            f+49 (0) 9132 84-2241
        </p>
        <p>
            E-Mail: <a href="mailto:rsp-support@adidas.com">rsp-support@adidas.com</a>
        </p>
        <p>
            Entry in the Commercial Register of the Fürth District Court (Amtsgericht): HRB 3868<br>
            Identification number for turnover tax: DE 132490588
        </p>
        <p>
            <strong>Executive Board</strong>
        </p>
        <ul class="imprint-dialog__list">
            <li>Bjørn Gulden (CEO)</li>
            <li>Harm Ohlmeyer</li>
            <li>Mathieu Sidukpohou</li>
            <li>Michelle Robertson</li>
        </ul>
        <p>
            <strong>Supervisory Board</strong><br>
            Thomas Rabe (Chairman)
        </p>

    </div>
</ng-template>

<ng-template #dialogFooter>
    <div class="standard-dialog--footer">
        <rsp-button
            [submodule]="'secondary'"
            (clicked)="closeDialog()"
            class="standard-dialog--button"
        >
            Ok
        </rsp-button>
    </div>
</ng-template>
