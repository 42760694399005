<div
    [rspLoadingIndicator]="isLoading"
    class="dashboard"
    xmlns="http://www.w3.org/1999/html">
    <div class="dashboard--main-column">
        <h1 class="dashboard--main-headline">My Dashboard</h1>


        <section class="dashboard--card">
            <h2 class="dashboard--card-headline">Welcome to RSP Article & File Management</h2>
            <div class="dashboard--card-content">
                <p>
                    <strong>
                        If you face any difficulties related to access rights, please contact rsp.support@adidas.com
                    </strong>
                </p>
                <p>
                    <strong>
                        If you face any problems related to concepts/articles visibility, please reach out to ntp_catalogue@adidas-group.com
                    </strong>
                </p>
            </div>
        </section>

        <section *ngIf="quickAccessItems.length > 0" class="dashboard--card">
            <h2 class="dashboard--card-headline">Quick Access</h2>

            <div class="dashboard--card-content">
                <div class="dashboard--quick-access">
                    <a
                        *ngFor="let quickAccessItem of quickAccessItems"
                        [routerLink]="[ quickAccessItem.url ]"
                        [class]="quickAccessItem.description === 'Forecasts' ? 'dashboard--quick-access-link-disabled' : 'dashboard--quick-access-link'"
                    >
                        {{ quickAccessItem.description }}
                    </a>
                </div>
            </div>
        </section>

        <section *ngIf="quickAccessItems.length > 0" class="dashboard--card">
            <h2 class="dashboard--card-headline">Need Help?</h2>

            <div class="dashboard--card-content">
                <p>If you need help or further assistance, please reach out to <a href="mailto:rsp.support@adidas.com">RSP support</a></p>
                <vg-player>
                    <video [vgMedia]="media" #media id="singleVideo" preload="auto" controls>
                        <source src="/assets/videos/RSP_Tutorial.mp4" type="video/mp4">
                    </video>
                </vg-player>
            </div>
        </section>

    </div>

    <div class="dashboard--secondary-column">
        <rsp-welcome-user
            *ngIf="user"
            [user]="user"
        ></rsp-welcome-user>

        <section *ngIf="userHasArticleExtractRight" class="dashboard--card">
            <h2 class="dashboard--card-headline">My Exports</h2>

            <div class="dashboard--card-content">
                <rsp-file-exports [type]="'Extract'" [files]="extracts"></rsp-file-exports>
            </div>
        </section>
        <section class="dashboard--card">
            <h2 class="dashboard--card-headline">My Downloads</h2>

            <div class="dashboard--card-content">
                <rsp-file-exports [type]="'Export'" [files]="fileExports"></rsp-file-exports>
            </div>‚
        </section>
    </div>
</div>

